import { MissionsFilters } from '../types/types';
import { SocialNetworkEnum } from '../types/enums';
import { SocialRedirectPageEnum } from '../../global/types/enums';

export const dashboardApiEndpoints = {
    GET_USER_DATA: 'quests/user',
    GET_MISSIONS_DATA: (filters?: MissionsFilters, sort?: string, page = 1, perPage = 10) => {
        const filterParams = filters
            ? Object.entries(filters)
                .map(([key, value]) => `filter[${key}]=${encodeURIComponent(value)}`)
                .join('&')
            : '';

        const sortParam = sort ? `sort=${encodeURIComponent(sort)}` : '';
        const paginationParams = `page=${page}&perPage=${perPage}`;

        const params = [filterParams, sortParam, paginationParams]
            .filter((param) => param)
            .join('&');

        return `quests/missions${params ? `?${params}` : ''}`;
    },
    GET_MISSION_DATA: (guildSlug: string, slug: string) => `quests/missions/${slug}?guild_slug=${guildSlug}`,
    GET_MISSION_PREVIEW_DATA: (guildSlug: string, slug: string) => `quests/missions/${slug}/preview?guild_slug=${guildSlug}`,
    GET_PREVIOUS_MISSION_DATA: (slug: string) => `quests/missions/${slug}/previous`,
    GET_STREAK_DATA: 'quests/user/streak-history',
    GET_ACTIVE_USER_ROUND: 'quests/user-rounds/active',
    TOGGLE_SOUND_SETTING: 'quests/user/settings',
    FEEDBACK: 'quests/user/feedback',
    REPORT: 'reports',
    GET_TANGIBLE_REWARD: (id: string) => `quests/rewards/claims/${id}`,
    SET_TANGIBLE_REWARD: (id: string) => `quests/rewards/claims/${id}`,
    GET_USER_MISSIONS_DATA: 'quests/user/missions/completed',
    GET_USER_EXAMS_DATA: 'quests/user/missions/exams',
    GET_USER_ACHIEVEMENTS_DATA: 'quests/user/achievements',
    GET_USER_NFT_DATA: 'quests/user/nft',
    GET_USER_STATS: 'quests/user/stats',
    GET_USER_ACCOUNT_BALANCE: 'user/accounts/balance',
    MINT_USER_NFT: (nftId: string) => `quests/user/nft/${nftId}/mint`,
    GET_NFT_CERTIFICATES: 'quests/get-exam-nft-certificates',
    CREATE_INITIAL_USER_ROUND: 'quests/user-rounds/',
    DEACTIVATE_USER_ACCOUNT: 'user/deactivate',
    CHANGE_USER_PASSWORD: 'user/password',
    ADD_USER_PASSWORD: 'user/password/store',
    SEND_USER_EMAIL_VERIFICATION: 'user/verifications/email',
    VERIFY_USER_EMAIL_VERIFICATION_CODE: 'user/verifications/email/verify',
    SEND_USER_PASSWORD_RESET_LINK: 'user/password/send-reset-link',
    RESET_USER_PASSWORD: 'user/password/reset',
    STORE_PET: 'store-pet',
    GET_GUILD_INFO: (slug: string) => `quests/guilds/${slug}/show`,
    GET_GUILD_MISSIONS: (slug: string) => `quests/guilds/${slug}/missions`,
    GUILD_FOLLOW: 'quests/user/guilds/following',
    OWNER_LUCKY_DRAW_WINNER_REWARDS: (slug: string) => `quests/rewards/quest-missions/${slug}`,
    OWNER_LUCKY_DRAW_WINNERS_ALL: (slug: string) => `quests/rewards/quest-missions/${slug}/users`,
    OWNER_LUCKY_DRAW_WINNERS_BY_REWARD: (ulid: string) => `quests/rewards/${ulid}/users`,
    LUCKY_DRAW_WINNERS: (slug: string) => `quests/missions/${slug}/lucky-draw/winners`,
    LUCKY_DRAW_RESTART: (slug: string) => `quests/missions/${slug}/lucky-draw/restart`,
    UPDATE_USER_SOCIAL_ACCOUNT: 'user/socials/update',
    CONNECT_SOCIAL_NETWORK_WITH_REDIRECT: (
        socialNetwork: SocialNetworkEnum,
        redirectPage: SocialRedirectPageEnum,
        isPrimary: boolean,
    ) => `social-account/${socialNetwork}/redirect/${redirectPage}?is_primary=${isPrimary}`,
    GET_GUILDS_STUDIO_STATS: 'quests/studio/stats',
    GET_UNLOCK_STUDIO_STEPS_STATUS: 'quests/user/guilds/owner-role/steps',
    SET_UNLOCK_STUDIO_ROLE: 'quests/user/guilds/owner-role',
    SUBSCRIPTIONS: 'quests/user/settings/subscriptions',
    SET_FIREBASE_DEVICE_TOKEN: 'user/update-firebase-device-token',
    DELETE_STUDIO_GUILD: (slug: string) => `quests/guilds/${slug}`,
};
